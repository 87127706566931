/**
* @description Link.vue
*/
<template>
  <PageWithHeader>
    <template #title>
      <p class="title">{{ title }}</p>
    </template>
    <iframe class="frame" :src="`${url}${hyphen}token=${messageToken}`" />
  </PageWithHeader>
</template>

<script lang="ts">
import * as R from 'ramda'
import { defineComponent, onBeforeUnmount } from 'vue'
import { messageToken, useHandleMessage } from '@/worker/messagHandler'
import PageWithHeader from '@/provider/PageWithHeader.vue'

export default defineComponent({
  name: 'Link',
  components: { PageWithHeader },
  props: {
    url: {
      type: String,
      required: true,
    },
    title: String,
  },
  setup (props) {
    const handleMessage = useHandleMessage()

    window.addEventListener('message', handleMessage)

    onBeforeUnmount(() => {
      window.removeEventListener('message', handleMessage)
    })

    return {
      messageToken,
      hyphen: R.includes('?', props.url) ? '&' : '?',
    }
  },
})
</script>

<style scoped lang="scss">
  .frame {
    border: none;
    width: 100%;
    min-height: 100%;
  }

  .title {
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
